/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { faCopy } from "@fortawesome/free-solid-svg-icons"; // Import faCopy
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faCogs } from "@fortawesome/free-solid-svg-icons";
import { faXRay } from "@fortawesome/free-solid-svg-icons";
import { faLanguage } from "@fortawesome/free-solid-svg-icons";
import { faBookReader } from "@fortawesome/free-solid-svg-icons";

import Swal from "sweetalert2";
import Select from "react-select";

import Nav from "./Nav";
import config from "../appsetting.json";
import { Col, Row, Form, Button, Card, Container } from "react-bootstrap";

import DataTable from "react-data-table-component";

function Setting() {
  const [customer, setCustomer] = useState([]);
  const [allData, setAllData] = useState([]);
  const [searchView, setSearchView] = useState("");
  const [searchHP, setSearchHP] = useState("");
  const [searchBrand, setSearchBrand] = useState("");
  const [searchModality, setSearchModality] = useState("");
  const [records, setRecord] = useState([]);
  const [modalityData, setModalityData] = useState([]);
  const [characterData, setCharacterData] = useState([]);
  const [patientNameData, setPatientNameData] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("TH");
  const [DbSelect, SetDataByKey] = useState({});

  const [modalityCode_v, SetModalityCode_v] = useState("");
  const [modalityName_v, SetModalityName_v] = useState("");

  const [characterDesc_v, SetCharacterDesc_v] = useState("");
  const [patientDesc_v, SetPatientDesc_v] = useState("");
  const [patientQuery_v, SetPatientQuery_v] = useState("");
  const [patientLanguage_v, SetPatientLanguage_v] = useState("");
  const [patientKey_v, SetPatientKey_v] = useState("");

  useEffect(() => {
    loadModality();
    loadCharacter();
    loadPatientNameFormat();
    loadAlldata();
  }, []);
  const optionsModality = modalityData.map((modality) => ({
    value: modality.MODALITY_CODE,
    label: `${modality.MODALITY_CODE} - ${modality.MODALITY_DESC}`,
  }));
  const optionsCharacterSet = characterData.map((charac) => ({
    value: charac.CHARACTERSET_KEY,
    label: charac.CHARACTERSET_DESC,
  }));
  const optionsPatientNameFormat = patientNameData.map((namefor) => ({
    value: namefor.PATIENTNAME_FORMAT_KEY,
    label: namefor.PATIENTNAME_FORMAT_DESC,
    type: namefor.PATIENTNAME_LANGUAGE,
  }));
  const loadAlldata = async () => {
    try {
      const response = await fetch(config.ApiPathUrl + "getViewDetail", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data.status === 200) {
        setSelectedLanguage(data.data[0].TH === "Y" ? "TH" : "EN");
        setAllData(data.data);
        setRecord(data.data);
      } else {
        console.log("No Data Found");
      }
    } catch (error) {
      console.error("Error LoadBMS:", error);
    }
  };

  const data = allData.filter((data) => {
    if (
      searchView === "" &&
      searchHP === "" &&
      searchBrand === "" &&
      searchModality === ""
    ) {
      return data;
    } else if (
      data.VIEW_NAME?.toLowerCase().includes(searchView.toLowerCase()) ||
      data.HOSPITAL_NAME?.toLowerCase().includes(searchHP.toLowerCase()) ||
      data.BRAND_NAME?.toLowerCase().includes(searchBrand.toLowerCase()) ||
      data.MODALITY_CODE?.toLowerCase().includes(searchModality.toLowerCase())
    ) {
      return data;
    }

    return null; // Add a return statement at the end of the arrow function
  });

  //console.log('All Data : ' + allData);
  function handleFilter(event) {
    const value = event.target.value.toLowerCase();
    console.log(allData);
    const newData = allData.filter((row) => {
      return (
        row.VIEW_NAME?.toLowerCase().includes(value) ||
        row.HOSPITAL_NAME?.toLowerCase().includes(value) ||
        row.BRAND_NAME?.toLowerCase().includes(value) ||
        row.MODALITY_CODE?.toLowerCase().includes(value)
      );
    });

    console.log("New Data : " + newData);
    setRecord(newData);
  }

  const handleCopy = (textToCopy) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        Swal.fire({
          title: "Copied!",
          text: "The text has been copied to your clipboard.",
          icon: "success",
          confirmButtonText: "OK",
        });
      })
      .catch((error) => {
        console.error("Copy failed:", error);
      });
  };

  const columns = [
    {
      name: "ชื่อ View",
      selector: (row) => row.VIEW_NAME,
      sortable: true,
      width: "150px",
    },
    {
      name: "ชื่อโรงพยาบาล",
      selector: (row) => row.HOSPITAL_NAME,
      sortable: true,
      width: "150px",
    },
    {
      name: "Brand",
      selector: (row) => row.BRAND_NAME,
      sortable: true,
      width: "150px",
    },
    {
      name: "Modality",
      selector: (row) => row.MODALITY_CODE,
      sortable: true,
      width: "100px",
    },
    {
      name: "Query",
      selector: (row) => row.QUERY,
      sortable: true,
      width: "470px",
      cell: (row) => (
        <div className="d-flex align-items-center">
          <span className="text-ellipsis" style={{ width: "400px" }}>
            {row.QUERY}
          </span>
          <Button className="ms-2" onClick={() => handleCopy(row.QUERY)}>
            <FontAwesomeIcon icon={faCopy} />
          </Button>
        </div>
      ),
    },
    {
      name: "Edit",
      cell: (row) => (
        <Link to={`/VK/${row.VIEW_KEY}`}>
          <Button className="bg-success border-success">
            <FontAwesomeIcon icon={faEdit} /> Edit
          </Button>
        </Link>
      ), // Example of rendering a button
      width: "110px",
    },
    {
      name: "Delete",
      cell: (row) => (
        <Button
          className="bg-danger border-danger"
          onClick={() => handlerDelete(row.VIEW_KEY, row.VIEW_NAME)}
        >
          <FontAwesomeIcon icon={faTrash} /> Delete
        </Button>
      ), // Example of rendering a button
      width: "150px",
    },
  ];

  const handlerDelete = (key, name) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You are about to delete ${name}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("Deleting row:", key);
        DeleteViewByKey(key);
        loadAlldata();
      }
    });
  };

  const DeleteViewByKey = async (key) => {
    try {
      const response = await fetch(
        config.ApiPathUrl + "deleteViewByKey?key=" + key,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      console.log(data);
      if (data.status === 200) {
        console.log(data);
        Swal.fire("Deleted!", "Your record has been deleted.", "success");
      } else if (data.status === 404) {
        console.log("No Data Found");
        Swal.fire("Deleted!", "No Data Found.", "error");
      }
    } catch (error) {
      console.error("Error LoadBMS:", error);
      Swal.fire("Deleted!", error, "error");
    }
  };
  const loadModality = async () => {
    try {
      const response = await fetch(config.ApiPathUrl + "getModality", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data.status === 200) {
        setModalityData(data.data);
      } else {
        console.log("No Data Found");
      }
    } catch (error) {
      console.error("Error LoadBMS:", error);
    }
  };
  const handleModalityChange = (selectedOption) => {
    SetDataByKey((prev) => ({
      ...prev,
      MODALITY_CODE: selectedOption ? selectedOption.value : null,
    }));
    setIsModalityCodeDisabled(!!selectedOption);
    if (selectedOption) {
      const namePart = selectedOption.label.split("-")[1]?.trim();
      SetModalityCode_v(selectedOption.value);
      SetModalityName_v(namePart);
    } else {
      SetModalityCode_v("");
      SetModalityName_v("");
    }
  };
  const selectedModalityOptions =
    optionsModality.find((option) => option.value === DbSelect.MODALITY_CODE) ||
    null;

  // if (selectedModalityOptions) {
  //   modalityCode_v = selectedModalityOptions.value;
  //   modalityName_v = selectedModalityOptions.label.split('-')[1]?.trim();
  // }
  const [isModalityCodeDisabled, setIsModalityCodeDisabled] = useState(false);
  const [isPatientNameFormatDescDisabled, setIsPatientNameFormatDescDisabled] =
    useState(false);
  const loadCharacter = async () => {
    try {
      const response = await fetch(config.ApiPathUrl + "getCharacter", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data.status === 200) {
        setCharacterData(data.data);
      } else {
        console.log("No Data Found");
      }
    } catch (error) {
      console.error("Error LoadBMS:", error);
    }
  };
  const handleCharacterSetChange = (selectedOption) => {
    SetDataByKey((prev) => ({
      ...prev,
      CHARACTER_CODE: selectedOption ? selectedOption.value : null,
    }));
    if (selectedOption) {
      console.log(selectedOption);
      SetCharacterDesc_v(selectedOption.label);
    } else {
      SetCharacterDesc_v("");
    }
  };
  const selectedCharacterSetOptions =
    optionsCharacterSet.find(
      (option) => option.value === DbSelect.CHARACTER_CODE
    ) || null;
  const filteredOptionsPatientNameFormat = patientNameData.map((namefor) => ({
    patientKey_v: namefor.PATIENTNAME_FORMAT_KEY,
    value: namefor.PATIENTNAME_FORMAT_KEY,
    label: namefor.PATIENTNAME_FORMAT_DESC,
    patientQuery_v: namefor.PATIENTNAME_FORMAT_QUERY,
    patientLanguage_v: namefor.PATIENTNAME_LANGUAGE,
  }));
  const handlePatientNameFormat = (selectedOption) => {
    SetDataByKey((prev) => ({
      ...prev,
      PATIENT_NAME_FORMAT: selectedOption ? selectedOption.value : null,
    }));
    setIsPatientNameFormatDescDisabled(!!selectedOption);
    if (selectedOption) {
      // patientLanguage_v

      console.log(selectedOption);
      SetPatientKey_v(selectedOption.value);
      SetPatientDesc_v(selectedOption.label);
      SetPatientQuery_v(selectedOption.patientQuery_v);
      SetPatientLanguage_v(selectedLanguage);
      handleRadioChange({
        target: { value: selectedOption.patientLanguage_v },
      });
    } else {
      SetModalityCode_v("");
      SetModalityName_v("");
      SetPatientLanguage_v("TH");
    }
  };
  const selectedPatientFormatOptions =
    optionsPatientNameFormat.find(
      (option) => option.value === DbSelect.PATIENT_NAME_FORMAT
    ) || null;
  const loadPatientNameFormat = async () => {
    try {
      const response = await fetch(config.ApiPathUrl + "getPatientFormat", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data.status === 200) {
        setPatientNameData(data.data);
      } else {
        console.log("No Data Found");
      }
    } catch (error) {
      console.error("Error LoadBMS:", error);
    }
  };
  const handleRadioChange = (event) => {
    const value = event.target.value;
    console.log(value);
    setSelectedLanguage(value);
    SetDataByKey((prev) => ({
      ...prev,
      TH: value === "TH" ? "Y" : "N",
      EN: value === "EN" ? "Y" : "N",
    }));
  };
  const handleModalityClear = () => {
    handleModalityChange(null);
    SetModalityCode_v("");
    SetModalityName_v("");
  };
  const handleCharacterSetClear = () => {
    handleCharacterSetChange(null);
    SetCharacterDesc_v("");
  };
  const handlePatientNameFormatClear = () => {
    handlePatientNameFormat(null);
    SetPatientDesc_v("");
    SetPatientQuery_v("");
    SetPatientLanguage_v(null);
  };

  const AddAndUpdateModality = async () => {
    try {
      if (modalityCode_v !== "" && modalityName_v !== "") {
        if (
          modalityData.find(
            (modality) => modality.MODALITY_CODE === modalityCode_v
          )
        ) {
          const modalityValueUp = {
            MODALITY_CODE: modalityCode_v,
            MODALITY_DESC: modalityName_v,
          };

          const response = await fetch(config.ApiPathUrl + "updateModality", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(modalityValueUp),
          });

          const data = await response.json();

          if (response.ok) {
            if (data.status === 200) {
              Swal.fire({
                title: "Success!",
                text: "Updated Modality!",
                icon: "success",
                timer: 1000, // 1 seconds
                showConfirmButton: false
              }).then(() => {
                window.location.reload();
              });
              
            } else {
              console.log("Unexpected status:", data.status);
            }
          } else {
            console.error("Response error:", data);
          }
        } else {
          const modalityValueAdd = {
            MODALITY_CODE: modalityCode_v,
            MODALITY_DESC: modalityName_v,
          };

          const response = await fetch(config.ApiPathUrl + "addModality", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(modalityValueAdd),
          });

          const data = await response.json();

          if (response.ok) {
            if (data.status === 200) {
              Swal.fire({
                title: "Success!",
                text: "Added Modality!",
                icon: "success",
                timer: 1000, // 1 seconds
                showConfirmButton: false
              }).then(() => {
                window.location.reload();
              });
            } else {
              console.log("Unexpected status:", data.status);
            }
          } else {
            console.error("Response error:", data);
          }
        }
      } else {
      }
    } catch (error) {
      console.error("Error in AddModality:", error);
      Swal.fire(
        "Error!",
        "Failed to save the record. Please try again.",
        "error"
      );
    }
  };

  const AddAndUpdatePatientFormat = async () => {
    //console.log("test 123: "+optionsPatientNameFormat.value);
    try {
      if (patientDesc_v !== "" && patientQuery_v !== "") {
        if (
          patientNameData.find(
            (patient) => patient.PATIENTNAME_FORMAT_DESC === patientDesc_v
          )
        ) {
          const patientDescValueUp = {
            PATIENTNAME_FORMAT_KEY: patientKey_v,
            PATIENTNAME_FORMAT_DESC: patientDesc_v,
            PATIENTNAME_FORMAT_QUERY: patientQuery_v,
            PATIENTNAME_LANGUAGE: selectedLanguage,
          };

          //console.log("test 123: "+optionsPatientNameFormat.value);

          const response = await fetch(
            config.ApiPathUrl + "updatePatientFormat",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(patientDescValueUp),
            }
          );

          const data = await response.json();

          if (response.ok) {
            if (data.status === 200) {
              Swal.fire({
                title: "Success!",
                text: "Updated PatientFormat!",
                icon: "success",
                timer: 1000, // 1 seconds
                showConfirmButton: false
              }).then(() => {
                window.location.reload();
              });
            } else {
              console.log("Unexpected status:", data.status);
            }
          } else {
            console.error("Response error:", data);
          }
        } else {
          const PatientValueAdd = {
            PATIENTNAME_FORMAT_DESC: patientDesc_v,
            PATIENTNAME_FORMAT_QUERY: patientQuery_v,
            PATIENTNAME_LANGUAGE: selectedLanguage,
          };

          const response = await fetch(
            config.ApiPathUrl + "addPatientFormat",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(PatientValueAdd),
            }
          );

          const data = await response.json();

          if (response.ok) {
            if (data.status === 200) {
              Swal.fire({
                title: "Success!",
                text: "Added PatientFormat!",
                icon: "success",
                timer: 1000, // 1 seconds
                showConfirmButton: false
              }).then(() => {
                window.location.reload();
              });
            } else {
              console.log("Unexpected status:", data.status);
            }
          } else {
            console.error("Response error:", data);
          }
        }
      } else {
      }
    } catch (error) {
      console.error("Error in AddModality:", error);
      Swal.fire(
        "Error!",
        "Failed to save the record. Please try again.",
        "error"
      );
    }
  };
  const AddAndUpdateCharacter = async () => {
    try {
      if (characterDesc_v !== "") {
        console.log(characterDesc_v);
  
        // Check if selectedCharacterSetOptions is not null or undefined
        if (
          selectedCharacterSetOptions &&
          selectedCharacterSetOptions.value != null &&
          characterData.find((cha) => cha.CHARACTERSET_KEY === selectedCharacterSetOptions.value)
        ) {
          console.log(characterDesc_v);
          console.log(selectedCharacterSetOptions.value);
  
          const characterDescValueUp = {
            CHARACTERSET_KEY: selectedCharacterSetOptions.value,
            CHARACTERSET_DESC: characterDesc_v,
          };
  
          const response = await fetch(
            config.ApiPathUrl + "updateCharaterSet",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(characterDescValueUp),
            }
          );
  
          const data = await response.json();
  
          if (response.ok) {
            if (data.status === 200) {
              Swal.fire({
                title: "Success!",
                text: "Updated CharaterSet!",
                icon: "success",
                timer: 1000, // 1 seconds
                showConfirmButton: false
              }).then(() => {
                window.location.reload();
              });
            } else {
              console.log("Unexpected status:", data.status);
            }
          } else {
            console.error("Response error:", data);
          }
        } else {
          const CharacterValueAdd = {
            CHARACTERSET_DESC: characterDesc_v,
          };
  
          const response = await fetch(config.ApiPathUrl + "addCharaterSet", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(CharacterValueAdd),
          });
  
          const data = await response.json();
  
          if (response.ok) {
            if (data.status === 200) {
              Swal.fire({
                title: "Success!",
                text: "Added CharaterSet!",
                icon: "success",
                timer: 1000, // 1 seconds
                showConfirmButton: false
              }).then(() => {
                window.location.reload();
              });
            } else {
              console.log("Unexpected status:", data.status);
            }
          } else {
            console.error("Response error:", data);
          }
        }
      } else {
        console.log("characterDesc_v is empty");
      }
    } catch (error) {
      console.error("Error in AddCharacter:", error);
      Swal.fire(
        "Error!",
        "Failed to save the record. Please try again.",
        "error"
      );
    }
  };
  

  return (
    <div>
      <Nav />
      <div className="d-flex">
        <main
          className="content w-100 d-flex justify-content-center"
          style={{ minHeight: "150px" }}
        >
          <Container
            className="card shadow p-3"
            style={{ marginTop: "15px", backgroundColor: "#f2f2f2" }}
          >
            <h4 className="mb-3">
              <FontAwesomeIcon icon={faCogs}></FontAwesomeIcon> Setting Page
            </h4>
            <hr />
            <Card
              style={{ marginTop: "15px", width: "100%" }}
              className="shadow"
            >
              <Card.Body>
                <div className="d-flex align-items-center mb-3">
                  <h5 className="me-2 d-flex align-items-center">
                    <FontAwesomeIcon icon={faXRay} />
                    <span className="ms-1">Modality</span>
                  </h5>
                  <div className="flex-grow-1">
                    <Select
                      options={optionsModality}
                      onChange={handleModalityChange}
                      isClearable
                      placeholder="Select or type to add..."
                      value={selectedModalityOptions}
                    />
                  </div>
                  <Button
                    style={{ backgroundColor: "#000000", borderStyle: "none" }}
                    className="ms-2"
                    onClick={handleModalityClear}
                  >
                    <i className="fa-solid fa-eraser"></i> Clear
                  </Button>
                </div>
                <hr />
                <Form>
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="formFirstInput">
                        <Form.Label>Modality Code</Form.Label>
                        <Form.Control
                          type="text"
                          value={modalityCode_v}
                          onChange={(e) => SetModalityCode_v(e.target.value)}
                          placeholder=""
                          //   value = {selectedModalityOptions ? '$(selectedModalityOptions.value)' : ''}
                          disabled={isModalityCodeDisabled}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="formSecondInput">
                        <Form.Label>Modality Name</Form.Label>
                        <Form.Control
                          type="text"
                          value={modalityName_v}
                          onChange={(e) => SetModalityName_v(e.target.value)}
                          placeholder=""
                        />
                      </Form.Group>
                    </Col>
                    {/* Align the button to the right on the same row */}
                    <Col
                      md={6}
                      className="d-flex align-items-end justify-content-end"
                    >
                      <Button
                        variant="primary"
                        type="button"
                        onClick={AddAndUpdateModality}
                      >
                        <i className="fas fa-save me-2"></i>Save
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>

            <Card
              style={{ marginTop: "15px", width: "100%" }}
              className="shadow"
            >
              <Card.Body>
                <div className="d-flex align-items-center mb-3">
                  <h5 className="me-2 d-flex align-items-center">
                    <FontAwesomeIcon icon={faLanguage} />
                    <span className="ms-1">Character Set</span>
                  </h5>
                  <div className="flex-grow-1">
                    <Select
                      options={optionsCharacterSet}
                      onChange={handleCharacterSetChange}
                      isClearable
                      placeholder="Select Character Set"
                      value={selectedCharacterSetOptions}
                    />
                  </div>
                  <Button
                    style={{ backgroundColor: "#000000", borderStyle: "none" }}
                    className="ms-2"
                    onClick={handleCharacterSetClear}
                  >
                    <i className="fa-solid fa-eraser"></i> Clear
                  </Button>
                </div>
                <hr />
                <Form>
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="formFirstInput">
                        <Form.Label>Character Desc</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={characterDesc_v}
                          onChange={(e) => SetCharacterDesc_v(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col
                      md={6}
                      className="d-flex align-items-end justify-content-end"
                    >
                      <Button
                        variant="primary"
                        type="button"
                        onClick={AddAndUpdateCharacter}
                      >
                        <i className="fas fa-save me-2"></i>Save
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>

            <Card
              style={{ marginTop: "15px", width: "100%" }}
              className="shadow"
            >
              <Card.Body>
                <div className="d-flex align-items-center mb-3">
                  <h5 className="me-2 d-flex align-items-center">
                    <FontAwesomeIcon icon={faBookReader} />
                    <span className="ms-1">Patient Name Format</span>
                  </h5>
                  <div className="flex-grow-1">
                    <Select
                      options={filteredOptionsPatientNameFormat}
                      onChange={handlePatientNameFormat}
                      isClearable
                      placeholder="Select Patient Name Format"
                      value={selectedPatientFormatOptions}
                    />
                  </div>
                  <Button
                    style={{ backgroundColor: "#000000", borderStyle: "none" }}
                    className="ms-2"
                    onClick={handlePatientNameFormatClear}
                  >
                    <i className="fa-solid fa-eraser"></i> Clear
                  </Button>
                  {/* <button className="btn btn-secondary ms-2">
                    Clear
                </button> */}
                </div>
                <hr />
                <Form>
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="formFirstInput">
                        <Form.Label>PatientName Format Desc</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={patientDesc_v}
                          onChange={(e) => SetPatientDesc_v(e.target.value)}
                          disabled={isPatientNameFormatDescDisabled}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="formSecondInput">
                        <Form.Label>PatientName Format Query</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={5}
                          type="text"
                          placeholder=""
                          value={patientQuery_v}
                          onChange={(e) => SetPatientQuery_v(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="formSecondInput">
                        <Form.Label>PatientName Language</Form.Label>
                        <div className="d-flex align-items-center">
                          <Form.Check
                            type="radio"
                            id="thai"
                            name="language"
                            label="TH"
                            value="TH"
                            className="me-3" // Add margin-end for spacing
                            checked={selectedLanguage === "TH"}
                            onChange={handleRadioChange}
                          />
                          <Form.Check
                            type="radio"
                            id="english"
                            name="language"
                            label="EN"
                            value="EN"
                            checked={selectedLanguage === "EN"}
                            onChange={handleRadioChange}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col
                      md={6}
                      className="d-flex align-items-end justify-content-end"
                    >
                      <Button
                        variant="primary"
                        className="ms-2"
                        type="button"
                        onClick={AddAndUpdatePatientFormat}
                      >
                        <i className="fas fa-save me-2"></i>Save
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Container>

          <div></div>
          <div></div>
        </main>
      </div>
    </div>
  );
}

export default Setting;
