import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '@fortawesome/fontawesome-free/css/all.min.css';
import {Container,Card, Row,Col,Form,Button,FormControl} from "react-bootstrap";
import Nav from "./Nav";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import logo from '../img/ThaiGL_logo.png';
import programmer from '../img/programmer.jpg';
import Swal from "sweetalert2";
import Select from "react-select";
import config from "../appsetting.json";
import CryptoJS from 'crypto-js';
import axios from 'axios';


const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = async () => {
    if (!username || !password) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Please enter both username and password!',
      });
      return;
    }
  
    const hashedPassword = CryptoJS.SHA256(password).toString(CryptoJS.enc.Hex);
    console.log("hashedPassword : " + hashedPassword);
  
    try {
      const payload = {
        userName: username,
        password: hashedPassword,
        apiKey: config.ApiKey,
      };
      const response = await fetch(
        config.ApiLoginUrl,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
  
      const data = await response.json(); // Parse the JSON from the response
      console.log("response data:", data);
  
      const { accessGranted, message, userName } = data;
  
      if (accessGranted) {
        Swal.fire({
          icon: 'success',
          title: 'Login Successful!',
          text: `Welcome, ${userName}!`,
        });
  
        window.location.href = "/Worklist";
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Login Failed',
          text: message || 'Invalid username or password!',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Login Failed',
        text: 'An error occurred while logging in!',
      });
      console.error('Login error:', error);
    }
  };
  
  

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div>
      <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh"}}>
        <main
          className="content w-100 d-flex justify-content-center"
          style={{ minHeight: "150px" }}
        >
          <Container
            className="card shadow p-3"
            style={{ marginTop: "15px", backgroundColor: "#f2f2f2" }}
          >
            <Card style={{ marginTop: '15px', width: '100%' }} className="shadow">
      <Card.Body>
        <div className="row">
          {/* Left Side: Image */}
          <div className="col-md-6 d-flex align-items-center justify-content-center">
            {/* <img
              src="/ThaiGl.png"
              alt="Modality"
              className="img-fluid"
              style={{ maxWidth: '100%' }}
            /> */}
            <img
            src={programmer}
            style={{ width: '90%' }} 
            className="d-inline-block align-top"
            alt="React Bootstrap logo"
            
          />
          </div>

          {/* Right Side: Login Form */}
          <div className="col-md-6">
            <div className="d-flex align-items-center mb-3">
            <img
            src={logo}
            className="d-inline-block align-top"
            style={{ width: '90%' }} 
            alt="React Bootstrap logo"
            
          />
              
            </div>
            <hr />
            <form>
            <h5 className="me-2 d-flex align-items-center">
                <FontAwesomeIcon icon={faSignInAlt} />
                <span className="ms-1">Login</span>
              </h5>
              <div className="form-group">
                <label htmlFor="loginUsername">Username</label>
                <input
                  type="text"
                  className="form-control"
                  id="loginUsername"
                  placeholder="Enter your Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="loginPassword">Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="loginPassword"
                  placeholder="Enter your Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <button
                type="button"
                onClick={handleLogin}
                className="btn btn-primary mt-3"
              >
                Log in
              </button>
            </form>
          </div>
        </div>
      </Card.Body>
    </Card>

          </Container>

          <div></div>
          <div></div>
        </main>
      </div>
    </div>
    
  );
};

export default LoginPage;