import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import logo from '../img/ThaiGL.png';
import { Link, useNavigate  } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Swal from 'sweetalert2';

const NavComponent = () => {

  const navigate = useNavigate();

  const handleLogout = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to logout?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, logout!',
    }).then((result) => {
      if (result.isConfirmed) {
        navigate('/Login');
      }
    });
  };

  return (
    <Navbar className="bg-success">
      <Container>
        <Navbar.Brand as={Link} to="/Worklist">
          <img
            src={logo}
            width="220"
            height="40"
            className="d-inline-block align-top"
            alt="React Bootstrap logo"
            
          />
        </Navbar.Brand>
        <Nav 
        className="ml-auto"
        >
          <Nav.Link as={Link} to="/AddView" className="nav-link text-white" >
          <i className="far fa-plus-square"></i> Add View
          </Nav.Link>
          <Nav.Link as={Link} to="/Worklist" className="nav-link text-white">
          <i className="fas fa-tasks"></i> Worklist
          </Nav.Link>
          <Nav.Link as={Link} to="/Setting" className="nav-link text-white">
          <i className="fas fa-cog"></i> Setting
          </Nav.Link>
          <Nav.Link onClick={handleLogout} className="nav-link text-white">
          <i className="fas fa-sign-out-alt"></i> Logout
          </Nav.Link>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default NavComponent;
