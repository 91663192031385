import React, { Component } from 'react';
import {BrowserRouter as Router, Switch, HashRouter, Routes, Route  } from 'react-router-dom';
import Homepage from './component/Homepage';
import Worklist from './component/Worklist';
import MyComponent from './component/Homepage';
import Setting from './component/Setting';
import Login from './component/Login';
import PrivateRoute from './component/PrivateRoute'; 

export default class App extends Component {
  renderRouter() {
    return (
      <Router>
      <Routes>
        <Route   path="/" element={<Login />} />
        <Route path="/Worklist" element={<Worklist/>} />
        <Route  exact path="/AddView" element={<Homepage/>} />
        <Route path="/VK/:VK" element={<MyComponent />} />
        <Route path="/Setting" element={<Setting />} />
        <Route path="/Login" element={<Login />} />
      </Routes>
      </Router>
    );
  }

  render() {
    return (
      <div>
      {this.renderRouter()}
    </div>
    );
  }
}
