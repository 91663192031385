/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { faCopy } from '@fortawesome/free-solid-svg-icons'; // Import faCopy
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Swal from "sweetalert2";

import Nav from "./Nav";
import config from "../appsetting.json";
import { Col, Row, Form, Button, Card, Container } from "react-bootstrap";

import DataTable from "react-data-table-component";

function Worklist() {
  const [customer, setCustomer] = useState([]);
  const [allData, setAllData] = useState([]);
  const [searchView, setSearchView] = useState("");
  const [searchHP, setSearchHP] = useState("");
  const [searchBrand, setSearchBrand] = useState("");
  const [searchModality, setSearchModality] = useState("");
  const [records, setRecord] = useState([]);
  useEffect(() => {
    loadAlldata();
  }, []);

  const loadAlldata = async () => {
    try {
      const response = await fetch(config.ApiPathUrl + "getViewDetail", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data.status === 200) {
        setAllData(data.data);
        setRecord(data.data);
      } else {
        console.log("No Data Found");
      }
    } catch (error) {
      console.error("Error LoadBMS:", error);
    }
  };

  const data = allData.filter((data) => {
    if (
      searchView === "" &&
      searchHP === "" &&
      searchBrand === "" &&
      searchModality === ""
    ) {
      return data;
    } else if (
      data.VIEW_NAME?.toLowerCase().includes(searchView.toLowerCase()) ||
      data.HOSPITAL_NAME?.toLowerCase().includes(searchHP.toLowerCase()) ||
      data.BRAND_NAME?.toLowerCase().includes(searchBrand.toLowerCase()) ||
      data.MODALITY_CODE?.toLowerCase().includes(searchModality.toLowerCase())
    ) {
      return data;
    }

    return null; // Add a return statement at the end of the arrow function
  });

  //console.log('All Data : ' + allData);
  function handleFilter(event) {
    const value = event.target.value.toLowerCase();
    console.log(allData);
    const newData = allData.filter((row) => {
      return (
        row.VIEW_NAME?.toLowerCase().includes(value) ||
        row.HOSPITAL_NAME?.toLowerCase().includes(value) ||
        row.BRAND_NAME?.toLowerCase().includes(value) ||
        row.MODALITY_CODE?.toLowerCase().includes(value)
      );
    });

    console.log("New Data : " + newData);
    setRecord(newData);
  }

  const handleCopy = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      Swal.fire({
        title: 'Copied!',
        text: 'The text has been copied to your clipboard.',
        icon: 'success',
        confirmButtonText: 'OK'
      });
    }).catch((error) => {
      console.error('Copy failed:', error);
    });
  };

  const columns = [
    {
      name: "ชื่อ View",
      selector: (row) => row.VIEW_NAME,
      sortable: true,

    },
    {
      name: "ชื่อโรงพยาบาล",
      selector: (row) => row.HOSPITAL_NAME,
      sortable: true,

    },
    {
      name: "Brand",
      selector: (row) => row.BRAND_NAME,
      sortable: true,

    },
    {
      name: "Modality",
      selector: (row) => row.MODALITY_CODE,
      sortable: true,
    },
    {
      name: "Character Set",
      selector: (row) => row.CHARACTERSET_DESC,
      sortable: true
    },
    {
      name: "Verify",
      selector: (row) => row.VERIFY,
      sortable: true,
    },
    {
    name: "Query",
    selector: row => row.QUERY,
    sortable: true,
    cell: row => (
      <div className="d-flex align-items-center">
        <Button
          className="ms-2"
          onClick={() => handleCopy(row.QUERY)}
        >
          <FontAwesomeIcon icon={faCopy} />
        </Button>
      </div>
    ),
  },
    {
      name: "Edit",
      cell: (row) => (
        <Link to={`/VK/${row.VIEW_KEY}`}>
        <Button className="bg-success border-success"><FontAwesomeIcon icon={faEdit}/> Edit</Button>
        </Link>
      ), // Example of rendering a button
      width: "110px",
    },
    {
      name: "Delete",
      cell: (row) => (
        <Button
          className="bg-danger border-danger"
          onClick={() => handlerDelete(row.VIEW_KEY, row.VIEW_NAME)}
        >
          <FontAwesomeIcon icon={faTrash}/> Delete
        </Button>
      ), // Example of rendering a button
      width: "150px",
    },
  ];

  const handlerDelete = (key, name) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You are about to delete ${name}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("Deleting row:", key);
        DeleteViewByKey(key);
        loadAlldata();
      }
    });
  };

  const DeleteViewByKey = async (key) => {
    try {
      const response = await fetch(
        config.ApiPathUrl + "deleteViewByKey?key=" + key,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      console.log(data);
      if (data.status === 200) {
        console.log(data);
        Swal.fire("Deleted!", "Your record has been deleted.", "success");
      } else if (data.status === 404) {
        console.log("No Data Found");
        Swal.fire("Deleted!", "No Data Found.", "error");
      }
    } catch (error) {
      console.error("Error LoadBMS:", error);
      Swal.fire("Deleted!", error, "error");
    }
  };

  return (
    <div>
      <Nav />
      <div className="d-flex">
        <main className="content w-100">
          <div className="table-settings mb-4 mt-4">
            <Container className="card shadow p-3">
              <h5 className="mb-3">
               <i className="fas fa-tasks"></i>{" "}
                  Worklist
                </h5>
                <hr></hr>
              <div className="d-flex justify-content-center">
                <input type="text" className="form-control w-50" onChange={handleFilter} placeholder="Search"/>
              </div>
              <DataTable
                columns={columns}
                data={records}
                fixedHeader
                pagination
              ></DataTable>
            </Container>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Worklist;
