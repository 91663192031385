import React, { useState, useEffect, useRef } from "react";
import { useParams  } from 'react-router-dom';
import {Container, Row,Col,Form,Button,FormControl} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Nav from "./Nav";
import CreatableSelect from "react-select/creatable";
import config from "../appsetting.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import Select from 'react-select';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Homepage = () => {
  const { VK } = useParams();
  const [modalityData, setModalityData] = useState([]);
  const [characterData, setCharacterData] = useState([]);
  const [patientNameData, setPatientNameData] = useState([]);
  const [textquery, setText] = useState("");
  const [viewName, setViewName] = useState("");
  const [viewHospital, setHospitalName] = useState('');
  const [viewBrand, setBrandName] = useState('');
  const [viewModel, setModelName] = useState('');
  const [viewRemask, setRemaskName] = useState('');
  const txtcodeRef = useRef(null);
  const [selectedLanguage, setSelectedLanguage] = useState('TH');
  const [selectedReferDoctor, setSelectedReferDoctor] = useState(false);
  const [selectedRequestDoctor, setSelectedRequestDoctor] = useState(false);
  const [selectedScheduledDoctor , setSelectedScheduledDoctor] = useState(false);
  const [selectVerify,setSelectedVerify] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(false);
  const [selectedSpsid , setSelectedSpsid] = useState(false);
  const [selectedPatientNameFormat,setSelectPatientNameFormat] = useState(null); 
  const [DbSelect,SetDataByKey]= useState({});

  // const optionsModality = modalityData.map((modality) => ({
  //   value: modality.MODALITY_CODE,
  //   label: modality.MODALITY_CODE + ' - ' + modality.MODALITY_DESC  ,
  // }));
  const optionsModality = modalityData.map((modality) => ({
    value: modality.MODALITY_CODE,
    label: `${modality.MODALITY_CODE} - ${modality.MODALITY_DESC}`,
  }));

  const optionsCharacterSet = characterData.map((charac) => ({
    value: charac.CHARACTERSET_KEY,
    label: charac.CHARACTERSET_DESC,
  }));

  const optionsPatientNameFormat = patientNameData.map((namefor) => ({
    value: namefor.PATIENTNAME_FORMAT_KEY,
    label: namefor.PATIENTNAME_FORMAT_DESC,
    type:  namefor.PATIENTNAME_LANGUAGE
  }));
  const selectedModalityOptions = DbSelect.MODALITY_CODE
  ? DbSelect.MODALITY_CODE.split(',').map(code => optionsModality.find(option => option.value.trim() === code.trim())).filter(option => option)
  : [];

  const selectedCharacterSetOptions = optionsCharacterSet.find(option => option.value === DbSelect.CHARACTER_CODE) || null;
  const selectedPatientFormatOptions = optionsPatientNameFormat.find(option => option.value === DbSelect.PATIENT_NAME_FORMAT) || null;

  const filteredOptionsPatientNameFormat = patientNameData
  .filter((namefor) => namefor.PATIENTNAME_LANGUAGE === selectedLanguage)
  .map((namefor) => ({
    value: namefor.PATIENTNAME_FORMAT_KEY,
    label: namefor.PATIENTNAME_FORMAT_DESC,
  }));
  //console.log("patientNameData : " + patientNameData)
  const filteredOptionPatientNameQuery = patientNameData
  .filter((namefor) => namefor.PATIENTNAME_FORMAT_KEY === DbSelect.PATIENT_NAME_FORMAT)
  .map((namefor) => ({
    value: namefor.PATIENTNAME_FORMAT_QUERY,
    label: namefor.PATIENTNAME_FORMAT_DESC,
  }));
  const filteredOptionPatientNameQueryValues = filteredOptionPatientNameQuery.map(option => option.value);

  //console.log("filteredOptionPatientNameQueryValues: ", filteredOptionPatientNameQueryValues);
  // console.log("filteredOptionPatientNameQuery : "+filteredOptionPatientNameQuery);


  useEffect(() => {
    loadModality();
    loadCharacter();
    loadPatientNameFormat();
    SetDataDefault()
    if(VK != null){
          loadDataByKey(VK);
    }
  }, []);

  const SetDataDefault = () => {
    SetDataByKey((prev) => ({
      ...prev,
      VIEW_NAME:null,
      HOSPITAL_NAME:null,
      BRAND_NAME:null,
      MODEL:null,
      MODALITY_CODE:null,
      CHARACTER_CODE:null,
      TH: 'Y',
      EN: 'N',
      VERIFY: 'N',
      PATIENT_NAME_FORMAT:null,
      DOCTOR:'Y',
      DEPARTMENT:'Y',
      SPSID:'Y',
      REMARK:null,
      QUERY:null
    }));

  };

  const loadModality = async () => {
    try {
      const response = await fetch(config.ApiPathUrl + "getModality", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data.status === 200) {
        setModalityData(data.data);
      } else {
        console.log("No Data Found");
      }
    } catch (error) {
      console.error("Error LoadBMS:", error);
    }
  };

  const loadCharacter = async () => {
    try {
      const response = await fetch(config.ApiPathUrl + "getCharacter", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data.status === 200) {
        setCharacterData(data.data);
      } else {
        console.log("No Data Found");
      }
    } catch (error) {
      console.error("Error LoadBMS:", error);
    }
  };
  const loadPatientNameFormat = async () => {
    try {
      const response = await fetch(config.ApiPathUrl + "getPatientFormat", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data.status === 200) {
        setPatientNameData(data.data);
      } else {
        console.log("No Data Found");
      }
    } catch (error) {
      console.error("Error LoadBMS:", error);
    }
  };
  const loadDataByKey = async (key) => {
    try {
      const response = await fetch(config.ApiPathUrl + "getViewDetailByKey?key=" + key, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data.status === 200) {
        console.log(data.data[0]);
         SetDataByKey(data.data[0]);
         setViewName(data.data[0].VIEW_NAME || '');
         setHospitalName(data.data[0].HOSPITAL_NAME || '')
         setBrandName(data.data[0].BRAND_NAME || '')
         setModelName(data.data[0].MODEL || '')
         setSelectedLanguage(data.data[0].TH === 'Y' ? 'TH' : 'EN');   
         setSelectedVerify(data.data[0].VERIFY === 'Y');
        //  setSelectedDoctor(data.data[0].DOCTOR === 'Y');
         setSelectedReferDoctor(data.data[0].DOCTOR === 'Y');
         setSelectedRequestDoctor(data.data[0].DOCTOR === 'Y');
         setSelectedScheduledDoctor(data.data[0].DOCTOR === 'Y');
         setSelectedDepartment(data.data[0].DEPARTMENT === 'Y');
         setSelectedSpsid(data.data[0].SPSID === 'Y');
         setText(data.data[0].QUERY || '');
         setRemaskName(data.data[0].REMARK || '')
      } else {
        console.log("No Data Found");
      }
    } catch (error) {
      console.error("Error LoadBMS:", error);
    }
  };

  const handleViewNameChange = (event) => {
    const value = event.target.value;
    setViewName(value);
    SetDataByKey((prev) => ({
      ...prev,
      VIEW_NAME: value,
    }));
  };

  const handleHospitalChange = (event) => {
    const value = event.target.value;
    setHospitalName(value);
    SetDataByKey((prev) => ({
      ...prev,
      HOSPITAL_NAME: value,
    }));
  };

  const handleBrandChange = (event) => {
    const value = event.target.value;
    setBrandName(value);
    SetDataByKey((prev) => ({
      ...prev,
      BRAND_NAME: value,
    }));
  };

  const handleModelChange = (event) => {
    const value = event.target.value;
    setModelName(value);
    SetDataByKey((prev) => ({
      ...prev,
      MODEL: value,
    }));
  
  };

  const handleModalityChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map(option => option.value);
    SetDataByKey((prev) => ({
      ...prev,
      MODALITY_CODE: selectedValues.join(', '), // Join selected values into a string
    }));
  };

  const handleCharacterSetChange = (selectedOption) => {
    SetDataByKey((prev) => ({
      ...prev,
      CHARACTER_CODE: selectedOption ? selectedOption.value : null,
    }));
  };

  const handleRadioChange = (event) => {
    const value = event.target.value;
    console.log(value);
    setSelectedLanguage(value);
    SetDataByKey((prev) => ({
      ...prev,
      TH: value === 'TH' ? 'Y' : 'N',
      EN: value === 'EN' ? 'Y' : 'N',
    }));
  };

  const handleVerifyChange = (event) => {
    const isChecked = event.target.checked;
    setSelectedVerify(isChecked);
    SetDataByKey((prev) => ({
      ...prev,
      VERIFY: isChecked ? 'Y' : 'N',
    }));
  };

  const handlePatientNameFormat = (selectedOption) => {
    SetDataByKey((prev) => ({
      ...prev,
      PATIENT_NAME_FORMAT: selectedOption ? selectedOption.value : null,
    }));
  };

  const handleTextChange = (e) => {
    const newText = e.target.value;
    setText(newText);
    SetDataByKey((prev) => ({
      ...prev,
      QUERY: newText,
    }));
  };

  const handleReferDoctorChange = (event) => {
    const isChecked = event.target.checked;
    setSelectedReferDoctor(isChecked);
    SetDataByKey((prev) => ({
      ...prev,
      DOCTOR: isChecked ? 'Y' : 'N',
    }));
  };
  const handleRequestDoctorChange = (event) => {
    const isChecked = event.target.checked;
    setSelectedRequestDoctor(isChecked);
    SetDataByKey((prev) => ({
      ...prev,
      DOCTOR: isChecked ? 'Y' : 'N',
    }));
  };

  const handleScheduledDoctorChange = (event) => {
    const isChecked = event.target.checked;
    setSelectedScheduledDoctor(isChecked);
    SetDataByKey((prev) => ({
      ...prev,
      DOCTOR: isChecked ? 'Y' : 'N',
    }));
  };

  const handleDepartmentChange = (event) => {
    const isChecked = event.target.checked;
    setSelectedDepartment(isChecked);
    SetDataByKey((prev) => ({
      ...prev,
      DEPARTMENT: isChecked ? 'Y' : 'N',
    }));
  };

  const handleSpsidChange = (event) => {
    const isChecked = event.target.checked;
    setSelectedSpsid(isChecked);
    SetDataByKey((prev) => ({
      ...prev,
      SPSID: isChecked ? 'Y' : 'N',
    }));
  };

  const handleRemaskChange = (event) => {
    const value = event.target.value;
    setRemaskName(value);
    SetDataByKey((prev) => ({
      ...prev,
      REMARK: value,
    }));
  };
  const GenerateCode = (key, name) => {
    if(viewName === ""){
      Swal.fire("warning!", "View Name Required!", "warning");
    }else{
      Swal.fire({
        title: "Are you sure?",
        text: `You are about to GenerateCode`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, GenerateCode it!",
      }).then((result) => {
        if (result.isConfirmed) {
          let chkModality = '';
            selectedModalityOptions.forEach((element,index) => {
              if (index > 0) {
                chkModality += ' OR ';
              }
               chkModality += `pc.modailty_code = '${element.value}'`;
            });
            const strModality = selectedModalityOptions.length === 0 ? '' : "AND ( " + chkModality + ")"; 
            const chkLanguage = selectedLanguage === "EN" ? "s.other_patient_name AS patient_name" : "pt.patient_name AS patient_name" ;
            //console.log("chkLanguage : " + chkLanguage);
            const chkPatientNameFormat = filteredOptionPatientNameQueryValues.length === 0 ? '' : filteredOptionPatientNameQueryValues;
            //console.log("chkPatientNameFormat : "+chkPatientNameFormat);
            const chkPatientNameFormatNull = chkPatientNameFormat === '' ? chkLanguage : chkPatientNameFormat;
            //console.log("chkFormet : " + chkPatientNameFormatNull);
            const chkDoctorup = selectedScheduledDoctor  ? "''" : "up.user_name " ;
            const chkDoctoruq = selectedRequestDoctor  ? "''" : "uq.user_name " ;
            const chkDoctoruf = selectedReferDoctor  ? "''" : "uf.user_name " ;
            const chkDepartment = selectedDepartment ? "''" : "od.order_department_code";
            const chkSpsid = selectedSpsid ? "''" : "ms.sps_id";
            const generatedQuery = `CREATE VIEW ${viewName} AS
                        SELECT
                            ms.msps_key AS msps_key,
                            -- scheduled procedure step
                            st.aetitle AS scheduled_station_aetitle,
                            ms.sps_start_dttm AS sps_start_datetime,
                            TO_CHAR(ms.sps_start_dttm, 'YYYYMMDD') AS sps_start_date,
                            TO_CHAR(ms.sps_start_dttm, 'HH24MISS') AS sps_start_time,
                            pc.modality_code AS scheduled_modality,
                            ${chkDoctorup} AS scheduled_perform_doctor,
                            pc.protocol_desc AS sps_desc,
                            st.station_name AS scheduled_station_name,
                            ms.sps_location AS sps_location,
                            ${chkSpsid} AS sps_id,
                            ms.pre_medication AS premedication,
                            ms.contrast_agent AS requested_contrast_agent,
                            ms.sps_stat AS sps_stat,
                            -- request procedure
                            s.study_id AS requested_proc_id,
                            pp.procplan_desc AS requested_proc_desc,
                            pp.code_value AS requested_proc_code_value,
                            pp.code_scheme AS requested_proc_code_scheme,
                            pp.code_version AS requested_proc_code_version,
                            pp.code_meaning AS requested_proc_code_meaning,
                            s.study_instance_uid || '.' || s.study_key AS study_instance_uid,
                            s.study_priority AS requested_proc_priority,
                            s.patient_arrange AS patient_transport_arrangements,
                            s.study_reason AS requested_proc_reason,
                            s.study_comments AS requested_proc_comments,
                            -- imaging service request
                            s.access_no AS accession_number,
                            ${chkDoctoruq} AS request_doctor,
                            ${chkDoctoruf} AS refer_doctor,
                            od.order_reason AS imaging_request_reason,
                            od.order_comments AS imaging_request_comments,
                            ${chkDepartment} AS requesting_service,
                            TO_CHAR(od.order_dttm, 'YYYYMMDD') AS imaging_request_date,
                            TO_CHAR(od.order_dttm, 'HH24MISS') AS imaging_request_time,
                            od.placer_order_id AS isr_placer_order_no,
                            od.filler_order_id AS isr_filler_order_no,
                            -- visit identification
                            s.institution AS institution_name,
                            vt.visit_no AS admission_id,
                            -- visit status
                            vt.patient_location AS patient_location,
                            vt.patient_residency AS patient_residency,
                            -- patient identification
                            pt.patient_name AS patient_name,
                            pt.patient_id AS patient_id,
                            s.patient_id_issuer AS patient_id_issuer,
                            ${chkPatientNameFormatNull},
                            pt.patient_id AS other_patient_id,
                            s.english_patient_name AS english_patient_name,
                            -- patient demographic
                            TO_CHAR(pt.patient_birth_dttm, 'YYYYMMDD') AS patient_birth_dttm,
                            pt.patient_sex AS patient_sex,
                            pt.patient_weight AS patient_weight,
                            pt.confidentiality AS confidentiality,
                            pt.patient_size AS patient_size,
                            -- patient medical
                            pt.patient_stat AS patient_state,
                            vt.pregnancy_status AS pregnancy_status,
                            pt.medical_alerts AS medical_alerts,
                            pt.contrast_allergies AS contrast_allergies,
                            pt.special_needs AS special_needs,
                            s.study_reason AS patient_comments
                        FROM
                            iorder od
                            JOIN study s ON od.order_key = s.order_key
                            JOIN msps ms ON s.study_key = ms.study_key
                            JOIN procplan pp ON s.procplan_key = pp.procplan_key
                            JOIN protocol pc ON ms.protocol_key = pc.protocol_key
                            JOIN visit vt ON od.visit_key = vt.visit_key
                            JOIN patient pt ON pt.patient_key = od.patient_key
                            JOIN station st ON ms.station_key = st.station_key
                            LEFT JOIN SV_MASTER_USERS up ON ms.perform_doctor_key = up.user_key
                            JOIN users uq ON od.order_doctor_key = uq.user_key
                            JOIN users uf ON od.refer_doctor_key = uf.user_key
                        WHERE
                            ms.sps_stat = 'A' 
                            ${strModality}
                        WITH READ ONLY;`;
    
                        setText(generatedQuery);
                        SetDataByKey((prev) => ({
                          ...prev,
                          QUERY: generatedQuery,
                        }));
        }
      });
    }
  
  };
  const createOption = (label) => ({
    MODALITY_CODE : label,
    MODALITY_DESC : label,
  });

  const SaveData = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You are about to GenerateCode`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, GenerateCode it!",
    }).then((result) => {
      if (result.isConfirmed) {
        AddView();
      }
    });
   
  };

  const AddView = async()=>{
    try {
      const response = await fetch(config.ApiPathUrl + "addView", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...DbSelect
        }),
      });
      const data = await response.json();
      if (data.status === 200) {
        Swal.fire({
          title: "Success!",
          text: "Save Data Success",
          icon: "success",
          confirmButtonText: "OK"
      }).then((result) => {
          if (result.isConfirmed) {
              // Redirect to the Worklist page
              window.location.href = "/worklist"; // Adjust this URL based on your routing
          }
      });
        
      }
    } catch (error) {
      Swal.fire("Error!", error, "error");
    }
  }

  const UpdateView = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You are about to UpdateCode`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Update it!",
    }).then((result) => {
      if (result.isConfirmed) {
        UpdateData();
      }
    });
   
  };
  const UpdateData = async () => {
    console.log("Update Data : " + JSON.stringify({
          ...DbSelect,
        }));
    try {
      const response = await fetch(config.ApiPathUrl + "updateViewByKey", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...DbSelect,
        }),
      });
      const data = await response.json();
      if (data.status === 200) {
        Swal.fire({
          title: "Success!",
          text: "Save Data Success",
          icon: "success",
          confirmButtonText: "OK"
      }).then((result) => {
          if (result.isConfirmed) {
              // Redirect to the Worklist page
              window.location.href = "/worklist"; // Adjust this URL based on your routing
          }
      });
      } 
    } catch (error) {
      Swal.fire("Error!", error, "error");
      console.log(error);
    }
  };

  const AddModality = async (newOption) => {
    try {
      console.log("JSON String : ")
      console.log(JSON.stringify({
        ...newOption
      }));
      const response = await fetch(config.ApiPathUrl + "addModality",{
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...newOption
        }),
      });
      const data = await response.json();
      if (data.status === 200) {
        Swal.fire({
          title: "Success!",
          text: "Add Modality Success",
          icon: "success",
          confirmButtonText: "OK"
      }).then((result) => {
          if (result.isConfirmed) {
              // Redirect to the Worklist page
              // window.location.href = "/worklist"; // Adjust this URL based on your routing
              loadModality();
          }
      });
      }
    } catch (error) {
      Swal.fire("Error!", error, "error");
    }
  };

  

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState(optionsModality);
  const [value, setValue] = useState(null);

  const handleCreate = async (inputValue) => {
    setIsLoading(true);
    const newOption = createOption(inputValue);
    console.log("New Option : " + newOption);
    try {
      // Save new modality to the database
      await AddModality(newOption);
      // Update state with the new option
      setOptions((prev) => [...prev, newOption]);
      setValue(newOption);
    } catch (error) {
      // Handle any errors that occur during the save process
      Swal.fire("Error!", "An error occurred while saving the data.", error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div>
      <Nav />
      <div className="d-flex">
        <main className="content  w-100">
          <div className="table-settings mb-4 mt-4">
            <Container className="card shadow p-3">
              <Row>
                <h5 className="mb-3">
                <i className="far fa-plus-square"></i> Add View
                </h5>
                <hr></hr>
                {/* Left Side */}
                <Col
                  lg={6}
                  sm={6}
                  md={6}
                  style={{
                    backgroundColor: "#fff",
                    padding: "20px",
                    marginTop: "20px",
                    borderRight: "1px solid black",
                  }}
                >
                  <Form>
                    <Form.Group className="mb-3" controlId="formGridView">
                      <Form.Label>ชื่อ View</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter View Name"
                        onChange={handleViewNameChange}
                        value={viewName}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formGridHosName">
                      <Form.Label>ชื่อโรงพยาบาล</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Enter Hospital Name"
                        onChange={handleHospitalChange}
                        value={viewHospital}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formGridBrand">
                      <Form.Label>Brand</Form.Label>
                      <Form.Control type="text" 
                      placeholder="Enter brand"
                      onChange={handleBrandChange}
                      value={viewBrand}
                       />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formGridModel">
                      <Form.Label>Model</Form.Label>
                      <Form.Control type="text"
                      placeholder="Enter Model" 
                      onChange={handleModelChange}
                      value={viewModel}
                      />
                    </Form.Group>
            
                    <Form.Group className="mb-3" controlId="formGridModality">
                      <Form.Label>Modality</Form.Label>
                      <CreatableSelect
                        options={optionsModality}
                        onChange={handleModalityChange }
                        isClearable
                        isMulti
                        placeholder="Select or type to add..."
                        value={selectedModalityOptions}
                        onCreateOption={handleCreate}
                      />
                    </Form.Group>

                    <Form.Group
                      className="mb-3"
                      controlId="formGridCharacterSet"
                    >
                      <Form.Label>Character Set</Form.Label>
                      <Select
                        options={optionsCharacterSet}
                        onChange={handleCharacterSetChange}
                        isClearable
                        placeholder="Select Character Set"
                        value={selectedCharacterSetOptions}
                      />
                    </Form.Group>

                    <div className="d-flex mb-3">
                      <Form.Check
                        type="radio"
                        id="thai"
                        name="language"
                        label="TH"
                         value="TH"
                        className="me-3"
                        checked={selectedLanguage === 'TH'}
                        onChange={handleRadioChange}
                      />
                      <Form.Check
                        type="radio"
                        id="english"
                        name="language"
                        label="EN"
                         value="EN"
                        checked={selectedLanguage === 'EN'}
                        onChange={handleRadioChange}
                      />
                    </div>

                    <div className="d-flex mb-3">
                      <Form.Check type="checkbox" 
                      label="Verify"
                      checked={selectVerify}
                      onChange={handleVerifyChange}
                      value={selectVerify}
                       />
                    </div>

                    <Form.Group className="mb-3"controlId="formGridPatientNameFormat"
                    >
                      <Form.Label>Patient Name Format</Form.Label>
                      <Select
                        options={filteredOptionsPatientNameFormat}
                        onChange={handlePatientNameFormat}
                        isClearable
                        placeholder="Select Patient Name Format"
                        value={selectedPatientFormatOptions}
                      />
                    </Form.Group>
                    <Form.Label style={{ color: 'red' }}>*Checked For Send Null Value</Form.Label>
                    <div className="d-flex mb-3">
                      <Form.Check
                        type="checkbox"
                        label="Refer Doctor"
                        className="me-3"
                        checked={selectedReferDoctor}
                        onChange={handleReferDoctorChange}
                        value={selectedReferDoctor}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Request Doctor"
                        className="me-3"
                        checked={selectedRequestDoctor}
                        onChange={handleRequestDoctorChange}
                        value={selectedRequestDoctor}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Scheduled Perform Doctor"
                        className="me-3"
                        checked={selectedScheduledDoctor}
                        onChange={handleScheduledDoctorChange}
                        value={selectedScheduledDoctor}
                      />
                    </div>
                    <div className="d-flex mb-3">
                    <Form.Check
                        type="checkbox"
                        label="Department"
                        className="me-3"
                        checked={selectedDepartment}
                        onChange={handleDepartmentChange}
                        value={selectedDepartment}
                      />
                      <Form.Check 
                        type="checkbox" 
                        label="Spsid Field"
                        className="me-3"
                        checked={selectedSpsid}
                        onChange={handleSpsidChange}
                        value={selectedSpsid}
                      />
                      
                    </div>

                    <Form.Group className="mb-3" controlId="formGridRemark">
                      <Form.Label>Remark</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Enter remarks"
                        onChange={handleRemaskChange}
                        value={viewRemask}
                      />
                    </Form.Group>
                    <div className="d-flex justify-content-center">
                      <Button
                        variant="primary"
                        type="button"
                        onClick={GenerateCode}
                      >
                        <i className="fas fa-cog"></i> GEN
                      </Button>
                    </div>
                  </Form>
                </Col>

                {/* Right Side */}
                <Col
                  lg={6}
                  md={6}
                  sm={6}
                  style={{
                    backgroundColor: "#fff",
                    padding: "20px",
                    marginTop: "20px",
                  }}
                >
                  <Form.Group
                    className="mb-3"
                    controlId="txtcode"
                  >
                    <Form.Label>Example textarea</Form.Label>
                    <FormControl
                      as="textarea"
                      rows={31}
                      ref={txtcodeRef}
                      value={textquery}
                      onChange={handleTextChange}
                      />
                  </Form.Group>

                  <div className="d-flex justify-content-center mb-3">
                  {VK ? (
                        <Button variant="primary" className="me-2" onClick={UpdateView}>
                          <i className="fas fa-sync-alt"></i> Update
                        </Button>
                      ) : (
                        <Button variant="primary" onClick={SaveData}>
                          <i className="fas fa-save"></i> Save
                        </Button>
                      )}
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Homepage;
